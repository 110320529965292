<template>
  <b-card
    v-if="pdriverinfo.Drivelicense"
    class="card-app-design"
  >
    <b-card-title class="mb-75">
      竞赛安全
    </b-card-title>

    <b-row class="design-planning-wrapper mb-1">
      <b-col class="design-planning">
        <p class="card-text mb-25">
          驾照扣分
        </p>
        <h6 :class="getDemeritVariant(driverInfo.Demerit)">
          {{ driverInfo.Demerit }}
        </h6>
      </b-col>
      <b-col class="design-planning">
        <p class="card-text mb-25">
          清分截止
        </p>
        <h6 class="mb-0">
          至{{ driverInfo.DemeritNextDate }}
        </h6>
      </b-col>
      <b-col class="design-planning">
        <p class="card-text mb-25">
          清分截止
        </p>
        <h6 class="mb-0">
          或{{ driverInfo.DemeritRestRaces }}场
        </h6>
      </b-col>
    </b-row>

    <div>
      <b-card-title class="mb-75">
        参赛限制
      </b-card-title>
      <b-badge
        v-if="driverInfo.Demerit < 6"
        variant="light-secondary"
        class="mr-1"
      >
        无参赛限制
      </b-badge>
      <b-badge
        v-if="driverInfo.Demerit >=6"
        variant="light-info"
        class="mr-1"
      >
        A级赛事禁入
      </b-badge>
      <b-badge
        v-if="driverInfo.Demerit >=8"
        variant="light-warning"
        class="mr-1"
      >
        B级赛事禁入
      </b-badge>
      <b-badge
        v-if="driverInfo.Demerit >= 12"
        variant="light-primary"
        class="ml-25"
      >
        所有赛事禁入
      </b-badge>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BBadge, BCardTitle, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BBadge,
    BCardTitle,
    BRow,
    BCol,
  },
  props: {
    pdriverinfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      driverInfo: this.pdriverinfo,
    }
  },
  watch: {
    pdriverinfo: {
      deep: true,
      handler(newVal) {
        this.driverInfo = newVal
        this.setSafetyInfo()
      },
    },
  },
  methods: {
    setSafetyInfo() {
      //
    },
    getDemeritVariant(val) {
      let content = 'mb-0 '
      if (val === 0) {
        content += 'text-success'
      } else if (val > 0 && val < 6) {
        content += 'text-waning'
      } else if (val >= 6 && val < 8) {
        content += 'text-danger'
      } else if (val >= 8 && val <= 12) {
        content += 'text-primary'
      } else if (val > 12) {
        content += 'bg-danger'
      }
      return content
    },
  },
}
</script>
