<template>
  <b-card-code
    title="参赛历史"
    no-body
  >
    <b-card-body>
      <b-form-group
        label-cols-sm="10"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-2"
      >
        <b-input-group size="sm">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="输入搜索内容"
          />
        </b-input-group>
      </b-form-group>

      <b-table
        responsive
        class="mb-0"
        :items="items"
        :fields="fields"
        :striped="striped"
        :bordered="bordered"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >
        <template #cell(Platform)="data">
          <img :src="require(`../../assets/images/games/${gatPlatformIcon(data.item.Platform)}.png`)">
        </template>

        <template #cell(RaceName)="data">
          <h-link
            :id="data.item.rid"
            :text="getRaceName(data.item)"
            to="race"
            type="race"
          />
        </template>

        <template #cell(TrackNameAbbr)="data">
          <h-link
            :id="data.item.tid"
            :text="data.item.TrackNameAbbr"
            to="track"
            type="track"
          />
        </template>

        <template #cell(BestLaptime)="data">
          {{ toLapTime(data.item.BestLaptime) }}
        </template>

        <template #cell(LadderDelta)="data">
          <span v-if="data.item.LadderDelta > 0">
            +{{ data.item.LadderDelta }}
          </span>
          <span v-else>
            {{ data.item.LadderDelta }}
          </span>
        </template>

        <template #cell(Round)="data">
          <span v-if="data.item.Round === 0">
            单轮制
          </span>
          <span v-else-if="data.item.Round === 1">
            第一轮
          </span>
          <span v-else>
            第二轮
          </span>
        </template>

        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap">

        <b-form-group
          label="每页显示："
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>

    </b-card-body>
  </b-card-code>
</template>

<script>
import Vue from 'vue'
import axios from '@axios'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BCardBody, BFormSelect, BPagination, BFormGroup, BInputGroup,
  BFormInput, BInputGroupPrepend,
} from 'bootstrap-vue'
import HLink from '@/views/common/h-link.vue'

export default {
  components: {
    BCardCode,
    BTable,
    BCardBody,
    BFormSelect,
    BPagination,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    HLink,
  },
  props: {
    pdriverid: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      history: this.pdriverhistory,
      fields: [
        { key: 'RaceDate', label: '日期', sortable: true },
        { key: 'Platform', label: '平台' },
        { key: 'RaceName', label: '赛事名称', sortable: true },
        { key: 'TrackNameAbbr', label: '赛道', sortable: true },
        { key: 'Class', label: '组别', sortable: true },
        { key: 'Veh', label: '车型', sortable: true },
        { key: 'GridPosition', label: '发车', sortable: true },
        { key: 'Position', label: '完赛', sortable: true },
        { key: 'BestLaptime', label: '最快圈', sortable: true },
        { key: 'Points', label: '积分', sortable: true },
        { key: 'IncidentPointTotal', label: '碰撞分', sortable: true },
        { key: 'LadderDelta', label: 'Rating', sortable: true },
        { key: 'Round', label: '轮次', sortable: true },
      ],
      items: [],
      striped: true,
      bordered: true,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    axios.get(`${Vue.prototype.$Api_Driver_Races}${this.pdriverid}`)
      .then(response => {
        this.items = response.data
        this.totalRows = this.items.length
      })
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getExpAvatar(exp) {
      return this.$getExpAvatar(exp)
    },
    toLapTime(value) {
      return this.$convertToLapTime(value)
    },
    gatPlatformIcon(value) {
      return this.$gatPlatformFileName(value)
    },
    getRaceName(item) {
      return `${item.SeriesName}第${item.RaceStage}站`
    },
  },
}
</script>
