<template>
  <b-card v-if="pdriverinfo.Drivelicense">
    <b-row>

      <!-- 左边部分 -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- 个人简要信息 -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="require(`../../assets/images/avatars/defaults/head${getAvatarNum()}.jpg`)"
            :text="pdriverinfo.NickName"
            size="108px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <span class="text-primary h3">{{ pdriverinfo.NickName }}</span>
            </div>
            <div>
              <span class="">赛照:</span>
              <b-img
                height="24"
                class="ml-1"
                :src="require(`../../assets/images/license/${pdriverinfo.Drivelicense.toUpperCase()}-24.png`)"
              />
            </div>
            <div>
              <span>HiRating:</span>
              <span class="card-text text-warning ml-1">{{ pdriverinfo.Ladder }}</span>
              <span class="ml-2">等级:</span>
              <span class="ml-1">Lv. </span>
              <b-avatar
                size="sm"
                :variant="this.$getExpAvatar(pdriverinfo.Exp)"
              >
                <span>{{ Math.floor(pdriverinfo.Exp / 100) + 1 }}</span>
              </b-avatar>
            </div>
            <div class="d-flex flex-wrap">
              <span class="mr-1">安全分: </span>
              <font-awesome-icon
                :class="safetyStars.variant1"
                :icon="safetyStars.icon1"
              />
              <font-awesome-icon
                :class="safetyStars.variant2"
                :icon="safetyStars.icon2"
              />
              <font-awesome-icon
                :class="safetyStars.variant3"
                :icon="safetyStars.icon3"
              />
              <font-awesome-icon
                :class="safetyStars.variant4"
                :icon="safetyStars.icon4"
              />
              <font-awesome-icon
                :class="safetyStars.variant5"
                :icon="safetyStars.icon5"
              />
              <span class="text-warning">({{ driverInfo.SafetyValue }})</span>
              <span class="ml-2 mr-1">近3场: </span>
              <span class="text-warning">{{ driverInfo.SafetyValueRecent }}</span>
            </div>
          </div>
        </div>

        <!-- 个人统计 -->
        <b-row class="d-flex align-items-center">
          <b-col class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-secondary"
              rounded
            >
              <feather-icon
                icon="ActivityIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ driverInfo.AttendRace }}
              </h5>
              <small>参赛</small>
            </div>
          </b-col>

          <b-col class="d-flex align-items-center">
            <b-avatar
              variant="light-secondary"
              rounded
            >
              <feather-icon
                icon="FlagIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ driverInfo.FinishRace }}
              </h5>
              <small>完赛</small>
            </div>
          </b-col>

          <b-col class="d-flex align-items-center">
            <b-avatar
              variant="light-secondary"
              rounded
            >
              <feather-icon
                icon="AwardIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ driverInfo.PodiumCount }}
              </h5>
              <small>领奖台</small>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <!-- 右侧数据 -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">首次参赛</span>
            </th>
            <td class="pb-50">
              {{ driverInfo.FirstRaceDate }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">首获积分</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ getProfileDate(driverInfo.FirstPointDate) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">首登领奖台</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ getProfileDate(driverInfo.FirstPodiumDate) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">生涯总积分</span>
            </th>
            <td class="pb-50">
              {{ driverInfo.CareerPoints }}分
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="TrendingUpIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">生涯总里程</span>
            </th>
            <td>
              {{ driverInfo.CareerRoute }}KM
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="TrendingUpIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">上次参赛</span>
            </th>
            <td>
              {{ plastrace }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BRow, BCol, BImg,
} from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStarHalfAlt, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faStarHalfAlt, faStar)

export default {
  components: {
    BCard, BRow, BCol, BAvatar, BImg, FontAwesomeIcon,
  },
  props: {
    pdriverinfo: {
      type: Object,
      required: true,
    },
    plastrace: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      driverInfo: this.pdriverinfo,
      safetyStars: {
        icon1: 'star',
        variant1: 'text-secondary',
        icon2: 'star',
        variant2: 'text-secondary',
        icon3: 'star',
        variant3: 'text-secondary',
        icon4: 'star',
        variant4: 'text-secondary',
        icon5: 'star',
        variant5: 'text-secondary',
      },
      drverInfo: this.pdriverinfo,
    }
  },
  watch: {
    pdriverinfo: {
      deep: true,
      handler(newVal) {
        this.driverInfo = newVal
        this.setSafetyStars()
      },
    },
  },
  created() {
  },
  methods: {
    getAvatarNum() {
      const level = Math.floor(this.pdriverinfo.Exp / 100) + 1
      let num = ''
      if (level < 5) {
        num = 1
      } else if (level < 15 && level >= 5) {
        num = 2
      } else if (level < 30 && level >= 15) {
        num = 3
      } else if (level < 46 && level >= 30) {
        num = 4
      } else {
        num = 5
      }
      return num
    },
    getLicense() {
      return this.pdriverinfo.Drivelicense.toUpperCase()
    },
    getProfileDate(value) {
      let date = value
      if (date === '') {
        date = '暂未获得'
      }
      return date
    },
    setSafetyStars() {
      const safety = Math.floor(this.drverInfo.SafetyValue + 0.5)
      switch (safety) {
        case 0:
          break
        case 1:
          this.safetyStars.variant1 = 'text-primary'
          this.safetyStars.icon1 = 'star-half-alt'
          break
        case 2:
          this.safetyStars.variant1 = 'text-primary'
          break
        case 3:
          this.safetyStars.variant1 = 'text-primary'
          this.safetyStars.variant2 = 'text-primary'
          this.safetyStars.icon2 = 'star-half-alt'
          break
        case 4:
          this.safetyStars.variant1 = 'text-primary'
          this.safetyStars.variant2 = 'text-primary'
          break
        case 5:
          this.safetyStars.variant1 = 'text-primary'
          this.safetyStars.variant2 = 'text-primary'
          this.safetyStars.variant3 = 'text-primary'
          this.safetyStars.icon3 = 'star-half-alt'
          break
        case 6:
          this.safetyStars.variant1 = 'text-primary'
          this.safetyStars.variant2 = 'text-primary'
          this.safetyStars.variant3 = 'text-primary'
          break
        case 7:
          this.safetyStars.variant1 = 'text-primary'
          this.safetyStars.variant2 = 'text-primary'
          this.safetyStars.variant3 = 'text-primary'
          this.safetyStars.variant4 = 'text-primary'
          this.safetyStars.icon4 = 'star-half-alt'
          break
        case 8:
          this.safetyStars.variant1 = 'text-primary'
          this.safetyStars.variant2 = 'text-primary'
          this.safetyStars.variant3 = 'text-primary'
          this.safetyStars.variant4 = 'text-primary'
          break
        case 9:
          this.safetyStars.variant1 = 'text-primary'
          this.safetyStars.variant2 = 'text-primary'
          this.safetyStars.variant3 = 'text-primary'
          this.safetyStars.variant4 = 'text-primary'
          this.safetyStars.variant5 = 'text-primary'
          this.safetyStars.icon5 = 'star-half-alt'
          break
        case 10:
          this.safetyStars.variant1 = 'text-primary'
          this.safetyStars.variant2 = 'text-primary'
          this.safetyStars.variant3 = 'text-primary'
          this.safetyStars.variant4 = 'text-primary'
          this.safetyStars.variant5 = 'text-primary'
          break
        default:
          break
      }
    },
  },
}
</script>

<style>

</style>
