<template>
  <div>
    <!-- 判断是否合法数据 -->
    <b-alert
      variant="danger"
      :show="driverId === null"
    >
      <h4 class="alert-heading">
        查无此人
      </h4>
      <div class="alert-body">
        无法根据用户ID：{{ currentDriverId }}查到此用户. 返回
        <b-link
          class="alert-link"
          :to="{ name: 'drivers'}"
        >
          车手列表
        </b-link>
        继续查看
      </div>
    </b-alert>

    <template v-if="driverId !== null">
      <!-- 第一行 -->
      <b-row>
        <b-col
          cols="12"
          xl="8"
          lg="8"
          md="7"
        >
          <DriverDetailsInfo
            :pdriverinfo="driverInfo"
            :plastrace="driverLastRace"
          />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="4"
          lg="4"
        >
          <DriverDemerit
            :pdriverinfo="driverInfo"
          />
        </b-col>
      </b-row>

      <!-- 第二行参赛历史 -->
      <DriverHistory
        :pdriverid="currentDriverId"
      />

      <!-- 第三行扣分历史 -->
      <DriverDemeritHisotry
        :pdriverid="currentDriverId"
      />
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from '@axios'
import {
  BAlert, BLink, BRow, BCol,
} from 'bootstrap-vue'
import DriverDetailsInfo from './driverDetailsInfo.vue'
import DriverDemerit from './driverDetailsDemerit.vue'
import DriverHistory from './driverDetailsHistory.vue'
import DriverDemeritHisotry from './driverDetailsDemeritHistory.vue'

export default {
  components: {
    BRow, BCol, BAlert, BLink, DriverDetailsInfo, DriverDemerit, DriverHistory, DriverDemeritHisotry,
  },

  data() {
    return {
      currentDriverId: 0,
      driverId: '',
      driverInfo: {
        Did: 0,
        NickName: '',
        AttendRace: 0,
        FinishRace: 0,
        PodiumCount: 0,
        FirstRaceDate: '',
        FirstPointDate: '',
        FirstPodiumDate: '',
        CareerPoints: 0,
        CareerRoute: '',
        SafetyValue: 0.1,
        Exp: 0,
        Ladder: 0,
        SafetyValueRecent: 0.1,
        Drivelicense: '',
        Demerit: 0,
        DemeritRestRaces: 0,
        DemeritNextDate: '',
      },
      driverLastRace: '',
    }
  },
  created() {
    this.currentDriverId = Number(this.$router.currentRoute.params.did)
    axios.get(`${Vue.prototype.$Api_Driver_Info}${this.currentDriverId}`)
      .then(response => {
        this.driverId = response.data[0].NickName
        this.driverInfo.Did = response.data[0].Did
        this.driverInfo.NickName = response.data[0].NickName
        this.driverInfo.AttendRace = response.data[0].AttendRace
        this.driverInfo.FinishRace = response.data[0].FinishRace
        this.driverInfo.PodiumCount = response.data[0].PodiumCount
        this.driverInfo.FirstRaceDate = response.data[0].FirstRaceDate
        this.driverInfo.FirstPointDate = response.data[0].FirstPointDate
        this.driverInfo.FirstPodiumDate = response.data[0].FirstPodiumDate
        this.driverInfo.CareerPoints = response.data[0].CareerPoints
        this.driverInfo.CareerRoute = response.data[0].CareerRoute
        this.driverInfo.SafetyValue = response.data[0].SafetyValue
        this.driverInfo.Exp = response.data[0].Exp
        this.driverInfo.Ladder = response.data[0].Ladder
        this.driverInfo.SafetyValueRecent = response.data[0].SafetyValueRecent
        this.driverInfo.Drivelicense = response.data[0].Drivelicense
        this.driverInfo.Demerit = response.data[0].Demerit
        this.driverInfo.DemeritRestRaces = response.data[0].DemeritRestRaces
        this.driverInfo.DemeritNextDate = response.data[0].DemeritNextDate
      })
    axios.get(`${Vue.prototype.$Api_Driver_LastRace}${this.currentDriverId}`)
      .then(response => {
        this.driverLastRace = response.data[0].LastRace
      })
  },
}
</script>
